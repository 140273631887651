// This file is a hack/workaround for this issue: https://github.com/TanStack/query/issues/3065
// The issue has a fix in the React query v5 branch, but it was not patched into v4. A solution
// that fixes it in v4 for all use cases sounds like a heavier lift, but for our use case, we
// can solve the issue pretty simply. The issue is that for useInfiniteQuery, your "select"
// function must return something in the shape of InfiniteData, even if you passed in an override
// TData. This file just redefines some types from within react query and moves the InfiniteData
// wrapper to be a default vs a requirement. This file can be removed in the upgrade to v5.

import {
  useInfiniteQuery as useInfiniteQueryWithUnpatchedTypes,
  QueryKey,
  ContextOptions,
  InfiniteData,
  QueryObserverOptions,
  InfiniteQueryObserverLoadingErrorResult,
  InfiniteQueryObserverLoadingResult,
  FetchNextPageOptions,
  FetchPreviousPageOptions,
  QueryObserverBaseResult,
} from "@tanstack/react-query";

export type InfiniteQueryObserverOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = QueryObserverOptions<TQueryFnData, TError, TData, InfiniteData<TQueryData>, TQueryKey>;

export interface UseInfiniteQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> extends ContextOptions,
    InfiniteQueryObserverOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey> {}

export interface InfiniteQueryObserverBaseResult<TData = unknown, TError = unknown>
  extends QueryObserverBaseResult<TData, TError> {
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult<TData, TError>>;
  fetchPreviousPage: (options?: FetchPreviousPageOptions) => Promise<InfiniteQueryObserverResult<TData, TError>>;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  isFetchingNextPage: boolean;
  isFetchingPreviousPage: boolean;
}

export interface InfiniteQueryObserverRefetchErrorResult<TData = unknown, TError = unknown>
  extends InfiniteQueryObserverBaseResult<TData, TError> {
  data: TData;
  error: TError;
  isError: true;
  isLoading: false;
  isLoadingError: false;
  isRefetchError: true;
  isSuccess: false;
  status: "error";
}

export interface InfiniteQueryObserverSuccessResult<TData = unknown, TError = unknown>
  extends InfiniteQueryObserverBaseResult<TData, TError> {
  data: TData;
  error: null;
  isError: false;
  isLoading: false;
  isLoadingError: false;
  isRefetchError: false;
  isSuccess: true;
  status: "success";
}

export type InfiniteQueryObserverResult<TData = unknown, TError = unknown> =
  | InfiniteQueryObserverLoadingErrorResult<TData, TError>
  | InfiniteQueryObserverLoadingResult<TData, TError>
  | InfiniteQueryObserverRefetchErrorResult<TData, TError>
  | InfiniteQueryObserverSuccessResult<TData, TError>;

export const useInfiniteQuery = useInfiniteQueryWithUnpatchedTypes as <
  TQueryFnData = unknown,
  TError = unknown,
  TData = InfiniteData<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>,
) => InfiniteQueryObserverResult<TData, TError>;
