import * as logClient from "@classdojo/log-client";
import { paths } from "@classdojo/ts-api-types";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { UseMutationOptions } from "@tanstack/react-query";
import callApi, { Method as CallApiMethod } from "@web-monorepo/infra/callApi";
import uniqueId from "lodash/uniqueId";
import { AnyAction, Dispatch } from "redux";
import { Response as SuperagentResponse } from "superagent";
import { APIRequestBody, APIRequestParameters, APIResponse, EndpointQueryParameters } from "../api/apiTypesHelper";
import { useDispatch } from "../utils/reduxHooks";
import { mergeFns } from "./queryUtils";
import { buildUrl } from "./urlBuilder";
import { urlPatternFromFetcher } from "./urlPatternFromFetcher";

export type OperationParams<Path extends keyof paths, Method extends keyof paths[Path]> =
  APIRequestBody<Path, Method> extends never
    ? APIRequestParameters<Path, Method>
    : APIRequestParameters<Path, Method> & { body: APIRequestBody<Path, Method> };

export type OperationResult<Path extends keyof paths, Method extends keyof paths[Path]> = Omit<
  SuperagentResponse,
  "body"
> & {
  body: APIResponse<Path, Method>;
};

// React Query options exposed at the configuration level. Some options are
// facilitated by makeMutation and some would cause unexpected behavior with
// the internal logic.
type ExposedUseMutationOptions<TData, TError, TVariables> = Omit<
  UseMutationOptions<TData, TError, TVariables>,
  "mutationFn" | "mutationKey"
>;

type MutationBaseAction<Params> = {
  type: string;
  payload: {
    params: Params;
  };
};

type MutationErrorAction<TError, Params> = { payload: { error: TError } } & MutationBaseAction<Params>;
type MutationSuccessAction<TData, Params> = { payload: { data: TData } } & MutationBaseAction<Params>;

const ACTION_TYPE_PREFIX = "$MU/";
const actions = {
  start: `${ACTION_TYPE_PREFIX}start`,
  done: `${ACTION_TYPE_PREFIX}done`,
  error: `${ACTION_TYPE_PREFIX}error`,
};
const getOperationActionType = (actionType: string, opName: string) => `${actionType}::${opName}`;

type ErrorWithExpectedErrorField = Error & {
  isExpectedError?: boolean;
};

async function mutationLogic<TData, E extends Error, Params>(
  fn: (params: Params) => Promise<TData | E>,
  params: Params,
  dispatch: Dispatch,
  mutationName: string,
  mutationId: string,
): Promise<TData> {
  dispatch({
    type: getOperationActionType(actions.start, mutationName),
    payload: { params, mutationName, mutationId },
  });

  try {
    const result = await fn(params);

    // Currently, mutations support supplying their own fn that can
    // catch and return errors (to silence them). We should refactor
    // those mutations to use useErrorBoundary instead to optionally
    // throw errors encountered.
    if (result instanceof Error) {
      (result as ErrorWithExpectedErrorField).isExpectedError = true;
      throw result;
    }

    logClient.logEvent({ eventName: `${logClient.getSite()}.mutation.${mutationName}.success`, automatedEvent: true });
    dispatch({
      type: getOperationActionType(actions.done, mutationName),
      payload: { params, data: result, mutationName, mutationId },
    });

    // TS has some issues over void/undefined.
    return result;
  } catch (ex) {
    if (!(ex instanceof Error)) {
      throw ex;
    }
    logClient.logEvent({ eventName: `${logClient.getSite()}.mutation.${mutationName}.failure`, automatedEvent: true });
    dispatch({
      type: getOperationActionType(actions.error, mutationName),
      payload: {
        params,
        error: ex,
        mutationName,
        mutationId,
      },
    });

    throw ex;
  }
}

export function makeMutation<Params, Result, E extends Error = Error>(
  config: {
    name: string;
    fn: (params: Params) => Promise<Result | E>;
  } & ExposedUseMutationOptions<Result, E, Params>,
): {
  (options?: ExposedUseMutationOptions<Result, E, Params>): UseMutationResult<Result, E, Params>;

  isStartAction(action: AnyAction): action is MutationBaseAction<Params>;
  isDoneAction(action: AnyAction): action is MutationSuccessAction<Result, Params>;
  isErrorAction(action: AnyAction): action is MutationErrorAction<E, Params>;
} {
  const {
    name: mutationName,
    fn: mutationFn,
    onMutate: onMutateConfig,
    onSuccess: onSuccessConfig,
    onError: onErrorConfig,
    onSettled: onSettledConfig,
    ...extraConfig
  } = config;

  const mutationId = uniqueId(`${mutationName}_execution_`);

  function useMutationWrapper(options: ExposedUseMutationOptions<Result, E, Params> = {}) {
    const dispatch = useDispatch();

    const {
      onMutate: onMutateOption,
      onSuccess: onSuccessOption,
      onError: onErrorOption,
      onSettled: onSettledOption,
      ...extraOptions
    } = options;

    return useMutation<Result, E, Params>(
      (params: Params) => mutationLogic<Result, E, Params>(mutationFn, params, dispatch, mutationName, mutationId),
      {
        mutationKey: [mutationId],
        useErrorBoundary: (error) => {
          // useErrorBoundary decides whether the error should be thrown
          // or not. If the error is not thrown, it will be returned as
          // mutation.error.
          return !("isExpectedError" in error || ("isExpected" in error && error.isExpected));
        },
        onMutate: mergeFns(onMutateConfig, onMutateOption),
        onSuccess: mergeFns(onSuccessConfig, onSuccessOption),
        onError: mergeFns(onErrorConfig, onErrorOption),
        onSettled: mergeFns(onSettledConfig, onSettledOption),
        ...extraConfig,
        ...extraOptions,
      },
    );
  }

  useMutationWrapper.isStartAction = (action: AnyAction): action is MutationBaseAction<Params> =>
    action.type === getOperationActionType(actions.start, mutationName);

  useMutationWrapper.isDoneAction = (action: AnyAction): action is MutationSuccessAction<Result, Params> =>
    action.type === getOperationActionType(actions.done, mutationName);

  useMutationWrapper.isErrorAction = (action: AnyAction): action is MutationErrorAction<E, Params> =>
    action.type === getOperationActionType(actions.error, mutationName);

  return useMutationWrapper;
}

export function makeApiMutation<
  Path extends keyof paths,
  Method extends keyof paths[Path] & string,
  E extends Error = Error,
  QueryParams extends keyof EndpointQueryParameters<Path> = keyof EndpointQueryParameters<Path>,
>({
  name,
  path,
  queryParams,
  method,
  catchError,
  ...additionalOptions
}: {
  name: string;
  path: Path;
  queryParams?: QueryParams[];
  method: Method;
  catchError?: (err: E) => E;
} & ExposedUseMutationOptions<OperationResult<Path, Method>, E, OperationParams<Path, Method>>) {
  const urlPattern = urlPatternFromFetcher(path, undefined, queryParams);

  return makeMutation<OperationParams<Path, Method>, OperationResult<Path, Method>, E>({
    name,
    fn: (params: OperationParams<Path, Method>): Promise<E | OperationResult<Path, Method>> => {
      const path = buildUrl({ urlPattern, params: { ...params.path, ...params.query } }, true);
      if (!path) {
        throw new Error(`Unable to buildUrl for mutation: ${name}`);
      }

      const resPromise = callApi({
        method: method.toUpperCase() as CallApiMethod,
        path,
        ...("body" in params && {
          body: params.body,
        }),
      });
      return catchError ? resPromise.catch(catchError) : resPromise.then((response) => response);
    },
    ...additionalOptions,
  });
}
