import { QueryClientProvider } from "@tanstack/react-query";
import React, { FC } from "react";
import { DevToolsSettings } from "../devTools/devToolsSettings";
import { isProd } from "../utils/env";
import { queryClient } from "./queryClient";

const ReactQueryDevtoolsDev = React.lazy(() =>
  import("@tanstack/react-query-devtools").then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

const ReactQueryDevtoolsProd = React.lazy(() =>
  // eslint-disable-next-line import/extensions
  import("@tanstack/react-query-devtools/build/lib/index.prod.js").then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

const ReactQueryDevTools = () => {
  const { isDevToolsEnabled: shouldShowDevTools } = DevToolsSettings.useIsDevToolsEnabled();
  if (!shouldShowDevTools) return null;

  return (
    <React.Suspense fallback={null}>
      {isProd ? <ReactQueryDevtoolsProd initialIsOpen={false} /> : <ReactQueryDevtoolsDev initialIsOpen={false} />}
    </React.Suspense>
  );
};

export const ReactQueryProvider: FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevTools />
      {children}
    </QueryClientProvider>
  );
};
